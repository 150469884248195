import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux'
import { AppReducer } from '../Components/App/Reducer/AppReducer'
import { LoginReducer } from '../Components/Forms/LoginForm/Reducer/LoginReducer'




const reducer = combineReducers({
  Apps: AppReducer,
  Login: LoginReducer,

});

const store = configureStore({
  reducer,
})

export default store;