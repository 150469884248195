import React, { Component, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'




import { IntlProvider } from 'react-intl';

import { v4 as uuid } from 'uuid';
import { storageGet, storageSet } from '../../../Libs/Collector/StorageAction'
import { sessionGet, sessionSet } from '../../../Libs/Collector/SessionAction'
import { LoadScript } from '../../../Libs/Services/GoogleLoader'

import { getApps } from '../Reducer/AppReducer'
import { Language } from '../../../Libs/Devices/Language'
import PrivateRoute from '../../../Libs/Auth/PrivateRoutes'
import Layouts from '../../Layout/Component/Layout';






const Login = React.lazy(() => import('../../../Pages/Login'));
const Signup = React.lazy(() => import('../../../Pages/Signup'))

const Dashboard = React.lazy(() => import('../../../Pages/Dashboard'));

const Users = React.lazy(() => import('../../../Pages/Settings/Users'));

const EventList = React.lazy(() => import('../../Event/EventList'));
const DeskManagement = React.lazy(() => import('../../DeskManagement/DeskManagement'));
const Terminal = React.lazy(() => import('../../Terminal/Terminal'));

const Booking = React.lazy(() => import('../../Booking/BookingList'));
const Company = React.lazy(() => import('../../../Pages/Settings/Company'));


const EventReport = React.lazy(()=>import('../../Report/EventReport'));

const Checkin = React.lazy(()=>import('../../Report/CheckinReport'));

export class App extends Component {


  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    LoadScript();


    if (Object.keys(storageGet("clientId")).length === 0 && storageGet("clientId").constructor === Object) {
      const datas = {
        key: uuid()
      }
      storageSet("clientId", datas);
    }


    if (Object.keys(storageGet("locale")).length === 0 && storageGet("locale").constructor === Object) {

      storageSet("locale", Language()[0]);

    }

    if (Object.keys(sessionGet("sessionId")).length === 0 && sessionGet("sessionId").constructor === Object) {

      const datas = {
        key: uuid()
      }
      sessionSet("sessionId", datas);

    }


    if (Object.keys(storageGet("clientId")).length !== 0 &&
      Object.keys(sessionGet("sessionId")).length !== 0
    ) {


      const datas = {
        clientId: storageGet("clientId"),
        sessionId: sessionGet("sessionId"),
        sId: Object.keys(storageGet("sId")).length !== 0 ? storageGet("sId") : "",
      }
      this.props.getApps(datas);

    }

  }


  render() {

    const { loading } = this.props.Apps

    return (
      <>
        {!loading &&
          <IntlProvider locale={storageGet("locale")} messages={""}>
            <BrowserRouter>
              <Routes>
                <Route exact element={<PrivateRoute />}>

                  <Route exact path="/Dashboard" element={
                    <Layouts>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Dashboard />
                      </Suspense>
                    </Layouts>} />



                  <Route exact path="/Settings/Users" element={
                    <Layouts>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Users />
                      </Suspense>
                    </Layouts>} />


                  <Route exact path="/Settings/Company" element={
                    <Layouts>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Company />
                      </Suspense>
                    </Layouts>} />
                  <Route exact path="/Event/Events" element={
                    <Layouts>
                      <Suspense fallback={<div>Loading...</div>}>
                        <EventList />
                      </Suspense>
                    </Layouts>} />

                  <Route exact path="/DeskManagement" element={
                    <Layouts>
                      <Suspense fallback={<div>Loading...</div>}>
                        <DeskManagement />
                      </Suspense>
                    </Layouts>} />

                  <Route path='/Terminal' element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <Terminal />
                    </Suspense>} />

                  <Route path='/Bookings' element={
                    <Layouts>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Booking />
                      </Suspense>
                    </Layouts>}
                  />

                  <Route path='/Report/EventReport' element={
                    <Layouts>
                      <Suspense fallback={<div>Loading...</div>}>
                        <EventReport />
                      </Suspense>
                    </Layouts>}
                  />
                  <Route path='/Report/Checkin' element={
                    <Layouts>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Checkin />
                      </Suspense>
                    </Layouts>}
                  />

                </Route>
                <Route path='/' element={

                  <Suspense fallback={<div>Loading...</div>}>
                    <Login />
                  </Suspense>
                } />
                <Route path='/Login' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Login />
                  </Suspense>} />
                <Route path='/Signup' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Signup />
                  </Suspense>} />
              </Routes>
            </BrowserRouter>
          </IntlProvider>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  Apps: state.Apps
})

const mapDispatchToProps = {
  getApps
}

export default connect(mapStateToProps, mapDispatchToProps)(App)