import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { storageSet } from '../../../../Libs/Collector/StorageAction'
import { setAuthorizationToken } from '../../../../Libs/Auth/setAuthToken'



export const getLogin = createAsyncThunk(
    'Login/getLogin',
    async (data) => {

        const apiData = {
            email: data.email,
            password: data.password,
            loginType:data.loginType,
            cfturnstile:data.cfturnstile
        }

        const res = await axios.post(process.env.REACT_APP_ADDRESS + `Login/Auth`, apiData);
        return res.data;
    })


export const LoginSlice = createSlice({
    name: 'Login',
    initialState: {
        loading: true,
        auth: false,
        companyUser: [],
        error: false,
        role:[],
        networkError:false,
        loginType:"1"

    },
    reducers: {
        LogoutReducer: (state) => {

            state.loading = false;
            state.auth = false;
            state.companyUser = [];
            state.role = [];
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("loginType");

        },
        RefershLoginReducer: (state, action) => {
            state.loading = false;
            state.auth = true;
            state.companyUser = action.payload.companyUser;
            state.role = action.payload.role;
            state.loginType=action.payload.loginType

            storageSet("loginType", action.payload.loginType);
            storageSet("token", action.payload.token);

            //setAuthorizationToken(action.token);
        },
        NetworkError:(state,action) => {

            console.log(action);
            if(action.payload) {
                state.networkError = true;
            } else {
                state.networkError = false;
            }
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getLogin.pending, (state, { payload }) => {
            state.loading = true;

            state.error = false;
        })

        builder.addCase(getLogin.fulfilled, (state, { payload }) => {
            state.loading = false;

            if (payload.status) {
                state.auth = true;
                state.companyUser = payload.companyUser;
                state.role = payload.role;
                state.loginType=payload.loginType;
                
                storageSet("loginType", payload.loginType);
                storageSet("token", payload.token);
                storageSet("refreshToken", payload.refreshToken);
                setAuthorizationToken(payload.token);

            } else {
                state.auth = false;
                state.companyUser = [];
                state.role = [];
                state.error = true;
            }
        })

        builder.addCase(getLogin.rejected, (state, { payload }) => {
            state.loading = false
        })

    }
})

export const { LogoutReducer, RefershLoginReducer, NetworkError } = LoginSlice.actions;



export const LoginReducer = LoginSlice.reducer