import React, { useState } from 'react';

import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    LogoutOutlined,
    BellOutlined,
    SearchOutlined

} from '@ant-design/icons';

import { Layout, Menu, theme, Result, Button } from 'antd';
import { Link } from 'react-router-dom';
//import logo from '../../../Assets/img/logo.svg'
import "../../../Assets/css/main.css"
import { LogoutReducer } from '../../Forms/LoginForm/Reducer/LoginReducer'
import { connect } from 'react-redux'

const { Header, Sider, Content } = Layout;





const Layouts = (props) => {

    const { brandName, firstName, lastName, logo, status } = props.Login.companyUser

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();






    const Logout = () => {

        props.LogoutReducer()
    }



    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        // console.log(icons)
        if (icons[type]) {
            const Component = icons[type];
            return <Component {...rest} />
        } else {
            const Component = icons["MenuFoldOutlined"];
            return <Component {...rest} />
        }

    }

    const reOrgMenus = () => {


        let menu = [];

        props.Login.role.filter(item => item.parentId === 0).forEach(item => {

            if (reSubOrgMenu(item.key).length > 0) {
                const a = {
                    key: item.key,
                    label: item.label,
                    icon: <Icon type={item.icon} />,
                    children: reSubOrgMenu(item.key)
                }
                menu.push(a);
            } else {
                let link = "#"

                if (item.value !== null) {
                    link = item.value
                } else {
                    link = "#"
                }
                const a = {
                    key: item.key,
                    label: <Link to={link}>{item.label}</Link>,
                    icon: <Icon type={item.icon} />,
                }
                menu.push(a);
            }



        });



        return menu;

    }


    const reSubOrgMenu = (id) => {
        let menu = [];
        props.Login.role.filter(item => item.parentId === id).forEach(item => {


            let link = "#"

            if (item.value !== null) {
                link = item.value
            } else {
                link = "#"
            }
            const a = {
                key: item.key,
                label: <Link to={link}>{item.label}</Link>,
            }
            menu.push(a);
        });
        return menu;
    }






    return (

        <>
            <Layout className='layout'>
                <Sider className='left' trigger={null} collapsible collapsed={collapsed}
                    style={{
                        overflow: 'auto',
                        minHeight: '100vh',
                    }}
                >
                    <div style={{ height: 32, margin: 16, background: 'rgba(255, 255, 255, 0.2)' }} />
                    <div className='menu'>
                        <div className="companyInfo">
                            <div className="avatar">
                                <img src={(logo === null || logo === "") ? "/Assets/images/logoIcon.svg" : ""} alt="icon" />
                            </div>
                            {!collapsed &&
                                <div className="infos">
                                    <div className="nameSurname">{firstName} {lastName}</div>
                                    <div className="companyName">{brandName}</div>
                                    {status === 1 ?
                                        <span className="badge bg-primary">Approved</span>
                                        :
                                        <span className="badge bg-warning" style={{ color: '#333' }}>Waiting</span>
                                    }

                                </div>
                            }
                        </div>
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        items={reOrgMenus()}
                    />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    >

                        <div className='row ms-1 me-1'>
                            <div className='col-md-1'>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: () => setCollapsed(!collapsed),
                            })}</div>
                            <div className='col-md-3 d-flex'>

                            </div>
                            <div className='col-md-8 text-end d-flex justify-content-end align-items-center'>
                                <div className="input-group form-control-sm" style={{ maxWidth: "300px" }}>

                                    <input type="text" className="form-control form-control-sm" placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" />
                                    <button className="btn btn-outline-primary" type="button" id="button-addon1"><SearchOutlined /></button>
                                </div>
                                <div className='headerIcons d-flex justify-content-end align-items-center'>

                                    <button className='btn align-items-center d-flex'>
                                        <BellOutlined /> <span className='ms-1'>Notifications</span>
                                    </button>

                                    <button onClick={() => Logout()} className='btn align-items-center d-flex'>
                                        <LogoutOutlined /> <span className='ms-1'>Logout</span>
                                    </button>

                                </div>


                            </div>
                        </div>

                    </Header>
                    <Content
                        style={{

                            padding: 30,


                        }}
                    >

                        {props.Login.networkError ?
                            <Result
                                status="warning"
                                title="There are some problems with your operation. Please check internet connection"
                                extra={
                                    <Button type="primary" key="console" onClick={()=>window.location.reload()} >
                                        Refersh Page
                                    </Button>
                                }
                            /> :
                            props.children
                        }


                    </Content>

                </Layout>
            </Layout>


        </>





    );
};


const mapStateToProps = (state) => ({
    Login: state.Login
})

const mapDispatchToProps = {
    LogoutReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(Layouts);
